@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

*,
*:before,
*:after {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1.5s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  animation: App-Colorful infinite 1.5s linear;
}

.App-link {
  color: #61dafb;
}

@keyframes App-Colorful {
  0% {
    background: red;
  }

  25% {
    background: green;
  }

  50% {
    background: purple;
  }

  75% {
    background: pink;
  }

  100% {
    background: blue;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: scaleX(1) scaleY(1) rotate(0deg);
  }

  25% {
    transform: scaleX(0.66) scaleY(0.66) rotate(90deg);
  }

  50% {
    transform: scaleX(1.3) scaleY(1.5) rotate(180deg);
  }

  75% {
    transform: scaleX(0.8) scaleY(1.1) rotate(270deg);
  }

  100% {
    transform: scaleX(1) scaleY(1) rotate(359deg);
  }
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  text-align: left;
}

.title {
  margin: 56px 0 24px 0;
}

h1 {
  line-height: 128%;
}

p {
  opacity: 0.8;
  font-family: Noto Sans KR, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  /* or 186% */
}

p.public {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 30px;
}

a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

p a:hover,
.footer a:hover {
  border-bottom: 2px solid #000000;
}

h5 {
  margin-top: 48px;
  font-size: 16px;
  line-height: 16px;
}

.footer {
  max-width: 608px;
  margin: 48px auto 48px auto;
  text-align: center;
}

.loader {
  color: black;
  opacity: 0.33;
}

.loader__text__background {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 15vw;
  transform: translateY(-50%);
  opacity: 0.15;
  line-height: 100%;
  margin: 0;
  padding: 0;
  /*border: 1px solid red;*/
}

.loader__text__background-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 100%;
  text-align: center;
}

.loader__text__background-container .loader__text__background {
  position: relative;
  display: block;
  transform: none;
  font-size: 33vw;
}

.loader__text {
  position: absolute;
  top: 50%;
  left: 16px;
  width: calc(100% - 32px);
  /*margin: -24px 0 0 0;*/
  margin: 0 auto;
  padding: 8px 0 0 0;

  font-size: 48px;
  line-height: 120%;
  text-align: center;
  opacity: 0;
  /*border: 1px solid blue;*/
}

@media (max-width: 820px) {
  .loader__text {
    font-size: 32px;
  }
}

@media (max-width: 560px) {
  .loader__text {
    font-size: 24px;
  }
}
